import React from 'react';
import './Homepage.css';

import Header from '../../components/Header/Header';
import Portfolio from '../../components/Portfolio/Portfolio';
import Footer from '../../components/Footer/Footer';

const Homepage = () => (
  <>
    <Header />
    <Portfolio />
    <Footer />
  </>
);

export default Homepage;
