import React from 'react';
import './Footer.css';
const getYear = () => {
  return new Date().getFullYear();
}
const Footer = () => (
  <footer className="footer-sec" style={{ backgroundColor: 'black' }}>
    <div className="container">
      
      <div className="row justify-content-center">
        <div className="row">
          <div className="col-xl-12">
            <div className="copyright-area">
              <h3>Redes Oficiales</h3>
              <p className="copyright-text" style={{ color: 'white' }}><strong><a target="_blank" rel="noreferrer" href='https://twitter.com/SquidCraftGames'> <span className="fab fa-twitter"></span> twitter.com/SquidCraftGames</a></strong></p>
              <p className="copyright-text" style={{ color: 'white' }}><strong><a target="_blank" rel="noreferrer" href='https://twitter.com/EufoniaStudio'><span className="fab fa-twitter"></span> twitter.com/EufoniaStudio</a></strong></p>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="row">
          <div className="col-xl-12">
            <div className="copyright-area">
              <p className="copyright-text" style={{ color: 'white' }}>Desarrollado con<span className="fas fa-heart"></span>por <strong><a href='https://www.ticroom.cl/'>Ticroom.cl</a></strong> @ <span className="current-year"></span> {getYear()}
                .</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
